import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'map-tracking',
    pathMatch: 'full'
  },
  {
    path: 'map-tracking',
    loadChildren: () => import('./pages/map-management/map-tracking/map-tracking.module').then( m => m.MapTrackingPageModule)
  },
  {
    path: 'video-conference',
    loadChildren: () => import('./pages/video/video-conference/video-conference.module').then( m => m.VideoConferencePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
